import { Highlight, themes } from 'prism-react-renderer';
import React, { ReactNode, useCallback, useState } from 'react';
import { PiCheck, PiCopy } from "react-icons/pi";
require("../../utils/prismLanguages")

export function generateId(text: string): string {
  return text
    .toLowerCase()
    // 文字列中の単語文字と日本語など特定の範囲の文字以外の全ての文字をハイフンに置換する
    .replace(/[^\w\u30A0-\u30FF\u3040-\u309F\u3000-\u303F\uFF00-\uFF9F\u4E00-\u9FFF]+/g, '-')
    // 文字列の最初または最後にあるハイフンを削除する
    .replace(/^-|-$/g, '');
}

export const H1: React.FC<{ children: string }> = ({ children }) => (
  <h1 className="text-4xl text-gray-700 dark:text-gray-200 mb-4">
    {children}
  </h1>
);

export const H2: React.FC<{ children: string }> = ({ children }) => (
  <h2
    id={generateId(children)}
    className="text-2xl md:text-3xl text-gray-700 dark:text-gray-200 mb-3 border-b border-solid border-gray-600 dark:border-gray-200 mt-6">
    {children}
  </h2>
);

export const H3: React.FC<{ children: string }> = ({ children }) => (
  <h3
    id={generateId(children)}
    className="text-xl md:text-2xl text-gray-700 dark:text-gray-200 mb-3 mt-6">
    {children}
  </h3>
);

export const H4: React.FC<{ children: string }> = ({ children }) => (
  <h4
    id={generateId(children)}
    className="md:text-xl text-gray-700 dark:text-gray-200 mb-2">
    {children}
  </h4>
);

export const H5: React.FC<{ children: string }> = ({ children }) => (
  <h5
    id={generateId(children)}
    className="md:text-lg text-gray-700 dark:text-gray-200 mb-2">
    {children}
  </h5>
);

export const H6: React.FC<{ children: string }> = ({ children }) => (
  <h6
    id={generateId(children)}
    className="text-base text-gray-700 dark:text-gray-200 mb-2">
    {children}
  </h6>
);

export const P: React.FC<{ children: ReactNode }> = ({ children }) => (
  <p className="text-base text-gray-700 dark:text-gray-300 mb-4">
    {children}
  </p>
);

export const Blockquote: React.FC<{ children: ReactNode }> = ({ children }) => (
  <blockquote className="border-l-4 border-gray-400 pl-4 italic text-gray-700 dark:text-gray-300 mb-4">
    {children}
  </blockquote>
);

export const A: React.FC<{ children: ReactNode, href: string }> = ({ children, href }) => (
  <a href={href} className="text-blue-500 hover:underline dark:text-blue-400">
    {children}
  </a>
);

export const Ul: React.FC<{ children: ReactNode }> = ({ children }) => (
  <ul className="list-disc pl-5 text-gray-700 dark:text-gray-300 mb-4">
    {children}
  </ul>
);

export const Ol: React.FC<{ children: ReactNode }> = ({ children }) => (
  <ol className="list-decimal pl-5 text-gray-700 dark:text-gray-300 mb-4">
    {children}
  </ol>
);

export const Li: React.FC<{ children: ReactNode }> = ({ children }) => (
  <li className="mb-2">
    {children}
  </li>
);

export const Del: React.FC<{ text: string }> = ({ text }) => (
  <del className="line-through">{text}</del>
);

export const Code: React.FC<{ children: ReactNode }> = ({ children }) => (
  <code className="px-1 py-0.5 text-sm md: text-base whitespace-pre-wrap bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md">
    {children}
  </code>
);

interface CodeBlockProps {
  children: ReactNode;
  className?: string;
}

// PreタグのCodeBlockについてはPrismを利用する
export const Pre: React.FC<CodeBlockProps> = ({ children, className }) => {
  let codeString = '';
  let language = className ? className.replace(/language-/, '') : 'jsx';

  // childrenがReactエレメントであり、期待したプロパティを持っていることを確認
  if (React.isValidElement(children) && children.props) {
    codeString = children.props.children;
    language = children.props.className ? children.props.className.replace(/language-/, '') : language;
  }

  // コピー状態を管理する
  const [copied, setCopied] = useState(false);
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(codeString.trim());
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  }, [codeString]);

  return (
    <div className="bg-codeblock-gray rounded-md mb-4">
      <div className="flex items-center relative text-gray-200 bg-gray-700 px-4 py-2 text-xs font-sans justify-between rounded-t-md">
        <span>{language}</span>
        <button className="flex ml-auto gap-2" onClick={handleCopy}>
          {copied ? <PiCheck className="icon-sm" /> : <PiCopy className="icon-sm" />}
          {copied ? 'コピーしました！' : 'コードをコピーする'}
        </button>
      </div>
      <div className="p-4 overflow-x-auto">
        <Highlight code={codeString.trim()} language={language} theme={themes.vsDark}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={`${className} !whitespace-pre text-sm md:text-base max-w-full`} style={{ ...style }}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
      </div>
    </div>
  );
};
