import { MDXProvider } from '@mdx-js/react';
import React from 'react';
import { A, Blockquote, Code, Del, H1, H2, H3, H4, H5, H6, Li, Ol, P, Pre, Ul } from './src/components/atoms/CustomComponents';
import './src/styles/global.css';

// MDXから生成するHTMLタグにスタイルを付与する
const components = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  p: P,
  blockquote: Blockquote,
  a: A,
  ul: Ul,
  ol: Ol,
  li: Li,
  del: Del,
  code: Code,
  pre: Pre,
};

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>
    {element}
  </MDXProvider>
);

// 要素を動的に追加してページの高さが動的に変わるページでスクロール位置を復元する
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.pathname === "/spoilerfriendlygamestrategy/armoredcore6/missionlist/") {
    const savedScrollY = sessionStorage.getItem("@@scrollY");
    if (savedScrollY) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(savedScrollY, 10));
      }, 500);  // 動的コンテンツのロード時間完了のため500msの遅延を設定。必要に応じてこの時間を調整
      return false; // Gatsbyのデフォルトのスクロール位置の保存の仕組みを使用しない
    }
  }
  return true; // それ以外のページではデフォルトのスクロール動作
};
